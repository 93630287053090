<button
  [id]="buttonId"
  mat-flat-button
  class="button-flat"
  (click)="onButtonClicked()"
  [ngStyle]="style"
  [style.width]="width"
  [style.minWidth]="minWidth"
  [style.background]="background"
  [style.border]="border"
  [style.borderRadius]="borderRadius"
>
  <div class="flex justify-center">
    @if(buttonIconLeft) {
    <img
      id="{{ buttonId }}IconLeft"
      class="button-flat-logo"
      [src]="buttonIconLeft"
    />
    }
    <span
      id="{{ buttonId }}Label"
      class="button-label"
      [style.fontSize.px]="fontSize"
      [style.color]="fontColor"
      [style.fontWeight]="fontWeight"
      >{{ buttonLabel | translate }}</span
    >
    @if(buttonIconRight) {
    <img
      id="{{ buttonId }}IconRight"
      class="button-flat-logo"
      [src]="buttonIconRight"
    />
    }
  </div>
</button>
