import {
  Component,
  inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './language-dropdown.interface';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'language-dropdown',
  templateUrl: 'language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    CommonModule,
  ],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'language-dropdown-overlay' },
    },
  ],
})
export class LanguageDropdown implements OnInit {
  translateService = inject(TranslateService);

  selected: any | null = null;

  isOverlayPanelOpened = false;

  dropDownOffset: number = 0;

  @Input() isWebView = true;

  languages: Language[] = [
    { languageLabel: 'English', languageCode: 'en' },
    { languageLabel: '中文', languageCode: 'zh-cn' },
  ];

  constructor() {}

  ngOnInit(): void {
    if (!this.translateService.currentLang) {
      this.selected = 'en';
    } else {
      this.selected = this.translateService.currentLang;
    }
  }

  updateLanguage(language: any): void {
    this.translateService.use(language.value);
  }

  mobileUpdateLanguage(language: string): void {
    this.selected = language;
    this.translateService.use(language);
  }

  onToggleClick(click: any): void {
    this.isOverlayPanelOpened = !this.isOverlayPanelOpened;
  }
}
