import {
  Component,
  HostListener,
  inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageDropdown } from '../language-dropdown/language-dropdown.component';
import { Router, RouterModule } from '@angular/router';
import { MatFlatButtonComponent } from '../mat-flat-button/mat-flat-button.component';
import { CommonModule } from '@angular/common';
import {
  MAT_MENU_DEFAULT_OPTIONS,
  MatMenuModule,
} from '@angular/material/menu';
import { LayoutModule } from '@angular/cdk/layout';

@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    LanguageDropdown,
    RouterModule,
    MatFlatButtonComponent,
    LayoutModule,
    CommonModule,
    MatMenuModule,
  ],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'topbar-mobile-menu-overlay',
      },
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TopbarComponent implements OnInit {
  translateService = inject(TranslateService);

  router = inject(Router);

  isWebView: boolean = false;

  ngOnInit(): void {
    this.isWebView = window.innerWidth > 1320;
  }

  login(): void {
    window.location.href = 'https://web.masterpayglobal.com/';
  }

  signUp(): void {
    window.location.href = 'https://web.masterpayglobal.com/accountAcces';
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: UIEvent) {
    this.isWebView = (event.target as Window).innerWidth > 1320;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollOffset =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (this.router.url === '/help') {
      if (scrollOffset > 485) {
        document.querySelectorAll('.topbar-wrapper').forEach((c) => {
          c.classList.add('dark-bg');
          c.classList.remove('transparent-bg');
        });
      } else {
        document.querySelectorAll('.topbar-wrapper').forEach((c) => {
          c.classList.add('transparent-bg');
          c.classList.remove('dark-bg');
        });
      }
    } else if (this.router.url === '/contact-us') {
      if (scrollOffset > 10) {
        document.querySelectorAll('.topbar-wrapper').forEach((c) => {
          c.classList.add('dark-bg');
          c.classList.remove('transparent-bg');
        });
      } else {
        document.querySelectorAll('.topbar-wrapper').forEach((c) => {
          c.classList.add('transparent-bg');
          c.classList.remove('dark-bg');
        });
      }
    } else {
      if (scrollOffset > window.innerHeight - 118) {
        document.querySelectorAll('.topbar-wrapper').forEach((c) => {
          c.classList.add('dark-bg');
          c.classList.remove('transparent-bg');
        });
      } else {
        document.querySelectorAll('.topbar-wrapper').forEach((c) => {
          c.classList.add('transparent-bg');
          c.classList.remove('dark-bg');
        });
      }
    }
  }
}
