import { Component, HostListener, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TopbarComponent } from './shared/topbar/topbar.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, TranslateModule, TopbarComponent, CommonModule],
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService) {
    this.translate.addLangs(['en', 'zh-cn']);
    this.translate.setDefaultLang('zh-cn');

    const browserLang = translate.getBrowserLang();
    if (browserLang) {
      this.translate.use(browserLang.match(/en|zh-cn/) ? browserLang : 'zh-cn');
    } else {
      this.translate.use('zh-cn');
    }
  }

  title = 'masterpay-ui';

  isMobileView: boolean = false;

  ngOnInit(): void {
    this.isMobileView = window.innerWidth <= 1320;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: UIEvent) {
    this.isMobileView = (event.target as Window).innerWidth <= 1320;
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../../i18n/', '.json');
}
