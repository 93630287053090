<div class="app-main-wrapper">
  <div class="topbar-main-wrapper">
    <topbar></topbar>
  </div>

  <div
    [ngClass]="isMobileView ? 'responsive-content-view ' : ''"
    class="content-main-wrapper"
  >
    <router-outlet></router-outlet>
  </div>
</div>
