<div class="topbar-wrapper" [ngClass]="isWebView ? '' : 'mobile-dark-bg'">
  <div class="topbar-left-content">
    <div class="topbar-masterpay-badge-wrapper">
      <div class="topbar-masterpay-icon"></div>
      @if(isWebView){
      <div class="topbar-masterpay-title">MasterPay Global</div>
      }
    </div>
    @if(isWebView){
    <div class="topbar-menu">
      <div class="topbar-menu-link">
        <a class="topbar-menu-text" routerLink="/home">{{
          "topbar.menu.home" | translate
        }}</a>
      </div>

      <div class="topbar-menu-link">
        <a class="topbar-menu-text" routerLink="/about-us">{{
          "topbar.menu.aboutUs" | translate
        }}</a>
      </div>

      <div class="topbar-menu-link">
        <a class="topbar-menu-text" routerLink="/features">{{
          "topbar.menu.feature" | translate
        }}</a>
      </div>

      <div class="topbar-menu-link">
        <a class="topbar-menu-text" routerLink="/security">{{
          "topbar.menu.security" | translate
        }}</a>
      </div>

      <div class="topbar-menu-link">
        <a class="topbar-menu-text" routerLink="/help">{{
          "topbar.menu.help" | translate
        }}</a>
      </div>

      <div class="topbar-menu-link">
        <a class="topbar-menu-text" routerLink="/career">{{
          "topbar.menu.career" | translate
        }}</a>
      </div>

      <div class="topbar-menu-link">
        <a class="topbar-menu-text" routerLink="/kyc-flow">{{
          "topbar.menu.kycFlow" | translate
        }}</a>
      </div>

      <div class="topbar-menu-link">
        <a class="topbar-menu-text" routerLink="/contact-us">{{
          "topbar.menu.contact" | translate
        }}</a>
      </div>
    </div>
    }
  </div>

  <div class="topbar-right-content">
    @if(isWebView) {
    <div class="topbar-button-wrapper">
      <mat-flat-button
        [buttonId]="'homeLoginButton'"
        buttonLabel="topbar.button.login"
        background="#4d5562"
        fontColor="#fff"
        width="100"
        class="topbar-button"
        (click)="login()"
      ></mat-flat-button>

      <mat-flat-button
        [buttonId]="'homeSignUpButton'"
        buttonLabel="topbar.button.signup"
        width="100"
        class="topbar-button"
        (click)="signUp()"
      ></mat-flat-button>
    </div>
    } @else () {
    <div class="topbar-button-wrapper">
      <mat-flat-button
        [buttonId]="'homeLoginButton'"
        buttonLabel="topbar.button.login"
        background="#090909"
        fontColor="#F1B90C"
        border="1px #F1B90C solid"
        borderRadius="15px"
        fontWeight="400"
        class="topbar-button"
        (click)="login()"
      ></mat-flat-button>

      <mat-flat-button
        [buttonId]="'homeSignUpButton'"
        buttonLabel="topbar.button.signup"
        borderRadius="15px"
        fontWeight="400"
        class="topbar-button"
        (click)="signUp()"
      ></mat-flat-button>
    </div>
    } @if(isWebView) {
    <div class="topbar-language-dropdown">
      <language-dropdown></language-dropdown>
    </div>
    } @else() {
    <div [matMenuTriggerFor]="menu" class="topbar-menu-icon-wrapper"></div>
    <mat-menu #menu="matMenu">
      <language-dropdown [isWebView]="false"></language-dropdown>
      <div mat-menu-item routerLink="/home">
        <div class="topbar-menu-link">
          <a class="topbar-menu-text">{{ "topbar.menu.home" | translate }}</a>
        </div>
      </div>
      <div mat-menu-item routerLink="/about-us">
        <div class="topbar-menu-link">
          <a class="topbar-menu-text">{{
            "topbar.menu.aboutUs" | translate
          }}</a>
        </div>
      </div>

      <div mat-menu-item routerLink="/features">
        <div class="topbar-menu-link">
          <a class="topbar-menu-text">{{
            "topbar.menu.feature" | translate
          }}</a>
        </div>
      </div>

      <div mat-menu-item routerLink="/security">
        <div class="topbar-menu-link">
          <a class="topbar-menu-text">{{
            "topbar.menu.security" | translate
          }}</a>
        </div>
      </div>

      <div mat-menu-item routerLink="/help">
        <div class="topbar-menu-link">
          <a class="topbar-menu-text">{{ "topbar.menu.help" | translate }}</a>
        </div>
      </div>

      <div mat-menu-item routerLink="/career">
        <div class="topbar-menu-link">
          <a class="topbar-menu-text">{{ "topbar.menu.career" | translate }}</a>
        </div>
      </div>

      <div mat-menu-item routerLink="/kyc-flow">
        <div class="topbar-menu-link">
          <a class="topbar-menu-text">{{
            "topbar.menu.kycFlow" | translate
          }}</a>
        </div>
      </div>

      <div mat-menu-item routerLink="/contact-us">
        <div class="topbar-menu-link">
          <a class="topbar-menu-text">{{
            "topbar.menu.contact" | translate
          }}</a>
        </div>
      </div>
    </mat-menu>
    }
  </div>
</div>
