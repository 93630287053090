@if(isWebView) {
<div class="language-dropdown-wrapper">
  <div class="language-dropdown-icon"></div>
  <mat-form-field>
    <mat-select
      hideSingleSelectionIndicator
      disableOptionCentering
      [(value)]="selected"
      (selectionChange)="updateLanguage($event)"
      (openedChange)="onToggleClick($event)"
      [class]="isOverlayPanelOpened ? 'overlay-open' : ''"
      panelClass="language-dropdown-options-wrapper"
    >
      <mat-select-trigger
        ><div>{{ selected === "en" ? "EN" : "CN" }}</div></mat-select-trigger
      >
      @for (language of languages; track language?.languageCode) {
      <mat-option [value]="language.languageCode">{{
        language.languageLabel
      }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
} @else {
<div class="mobile-language-select-wrapper">
  <div
    class="mobile-language-select"
    [ngClass]="selected === 'en' ? 'selected-language' : ''"
    (click)="mobileUpdateLanguage(languages[0].languageCode)"
  >
    {{ languages[0].languageLabel }}
  </div>
  <div
    class="mobile-language-select"
    [ngClass]="selected === 'zh-cn' ? 'selected-language' : ''"
    (click)="mobileUpdateLanguage(languages[1].languageCode)"
  >
    {{ languages[1].languageLabel }}
  </div>
</div>
}
